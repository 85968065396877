const dev = {
  API_URL: "http://localhost:4000/api",
  API_KEY: "3SATbuIEVJmmM9XPgO3uHKcQwSZtvkk",
};

const stage = {
  API_URL: "https://staging-api.joinsuperfi.com/api",
  API_KEY: "3SATbuIEVJmmM9XPgO3uHKcQwSZtvkk",
};

const prod = {
  API_URL: "https://api.joinsuperfi.com/api",
  API_KEY: "3SATbuIEVJmmM9XPgO3uHKcQwSZtvkk",
};

const config =
  process.env.REACT_APP_ENV === "PROD"
    ? prod
    : process.env.REACT_APP_ENV === "STAGE"
    ? stage
    : dev;

export default {
  ...config,
};
